import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button as Btn } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputValid } from "../../../validations/InputValid";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { profileAddUpdate, profileGet } from "../../../services/user";
import toastr from "toastr";
import { useAuth } from "../../../AuthContext";

export const Button = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [profile_status, setProfile_status] = useState("");
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [sponsorId, setSponsorId] = useState("");
  const [mobile_number, setmobile_number] = useState("+44");


  const [nameErr, setNameErr] = useState("");
  const [emailErr, setemailErr] = useState("");
  const [mobile_numberErr, setmobile_numberErr] = useState("");
  const [number, setNumber] = useState("");
  const [country_code, setCountry_code] = useState();


  const profileData = async () => {
    const data = { address };
    const token = localStorage.getItem("jwtToken");
    const resp = await profileGet(data, token);
    if (resp.status) {
      if( resp.data[0]?.mobile_number){
        setName(resp.data[0]?.name);
        setemail(resp.data[0]?.email);
        setmobile_number(resp.data[0]?.country_code + resp.data[0]?.mobile_number);
          setCountry_code(resp.data[0]?.country_code)
          setNumber(resp.data[0]?.mobile_number)
      }
      setSponsorId(resp?.sponsorId);
    }
  };

  useEffect(() => {
    profileData();
  }, [address]);

  const handleShow = async () => {
    const data = { address };
    const token = localStorage.getItem("jwtToken");
    const resp = await profileGet(data, token);
    if (resp.status) {
      if(resp.data[0]?.mobile_number){
        setName(resp.data[0]?.name);
        setemail(resp.data[0]?.email);
        setmobile_number(resp.data[0]?.country_code + resp.data[0]?.mobile_number);
        setCountry_code(resp.data[0]?.country_code)
        setNumber(resp.data[0]?.mobile_number)
      }
    }

    setShow(true);
  };
  const getData = async () => {
    if (address) {
      const data = { address };
      const token = localStorage.getItem("jwtToken");
      const resp = await profileGet(data, token);
      if (resp.status) {
        setProfile_status(resp.data[0]?.profile_status);

        if (resp.data[0]?.profile_status == 1) {
          setShow(false);
        } else {
          setShow(true);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, [address]);

  const handleClose = () => {
    setName("");
    setemail("");
    setmobile_number("+44");
    if (profile_status == 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleOnChanges = (value, country) => {
    
    setmobile_number(value);
    let adjustedMobile = value?.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setmobile_numberErr("Mobile Number is required");
    } else {
      setmobile_numberErr("");
    }
    setNumber(adjustedMobile);
    setCountry_code("+" + country.dialCode);
  };


  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "email") {
      setemail(value);
      const err = InputValid(name, value);
      setemailErr(err);
    }
    // if (name === "mobile_number") {
    //   setmobile_number(value);
    //   const err = InputValid(name, value);
    //   setmobile_numberErr(err);
    // }
  };

  const profileHandler = async () => {
    const checkQuestion = InputValid("name", name);
    if (checkQuestion) {
      setNameErr(checkQuestion);
      return false;
    }

    const Emailpattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    // exports.Namepattern = /^[a-zA-Z][a-zA-Z][a-zA-Z ]{0,18}$/i;
    const Mobilepattern = /^[1-9]\d{9}$/;

    const checkQuestion1 = InputValid("email", email);
    if (checkQuestion1) {
      setemailErr(checkQuestion1);
      return false;
    }

    if (!Emailpattern.test(email)) {
      setemailErr("Enter valid email address");
      return;
    }
    // const checkQuestion2 = InputValid("mobile_number", number);
    // if (checkQuestion2) {
    //   setmobile_numberErr(checkQuestion2);
    //   return false;
    // }

    // if (!Mobilepattern.test(mobile_number)) {
    //   setmobile_numberErr("Enter valid mobile number");
    //   return;
    // }
    if (number === "") {
      setmobile_numberErr("This field is required");
      return false;
    }

    const data = { name, email, mobile_number:number, address,country_code: country_code, };
    const token = localStorage.getItem("jwtToken");
    const res = await profileAddUpdate(data, token);

    if (res.status) {
      toastr.success(res.message);
      getData();
      handleClose();
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <>
     <h5 className="mr-md-4 ml-auto mb-3 mb-md-0 order-md-2">Welcome {name} </h5>
      <div className="img_box  d-inline-flex align-items-center order-md-3">
     

        {/* <h6>{sponsorId}</h6> */}
        <h6 className="mr-3 mb-0 d-flex align-items-center">
          Your Wallet Address:
        </h6>

        <span className="color1 pl-2 mr-2 ">
          <w3m-button balance="hide" />
        </span>
        <span
          className="dropdown-toggle d-inline-flex  align-items-center  cursor-pointer"
          data-toggle="dropdown"
        >
          <img
            alt
            className="max-h-full"
            src="/assets/img/user.png"
            onClick={() => handleShow()}
          />
        </span>
        <div className="dropdown-menu p-2">
          <button
            className="btn w100 btn_man_small"
            onClick={() => handleShow()}
          >
            Profile
          </button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add/Update Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Name </Form.Label>
              <Form.Control
                name="name"
                onChange={handlechange}
                type="text"
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                value={name}
              ></Form.Control>
              <span style={{ color: "red" }}>{nameErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Email </Form.Label>
              <Form.Control
                name="email"
                onChange={handlechange}
                type="text"
                value={email}
                // disabled={true}
              ></Form.Control>
              <span style={{ color: "red" }}>{emailErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Mobile number </Form.Label>
              {/* <Form.Control
                name="mobile_number"
                onChange={handlechange}
                type="text"
                value={mobile_number}
                // disabled={true}
              ></Form.Control> */}
                <PhoneInput
                    key={"phoneInput"}
                    country="IND"
                    value={mobile_number}
                    onChange={handleOnChanges}
                    className="input_item2"
                    placeholder="Mobile"
                    countryCodeEditable={false}
                    enableSearch={true}
                    inputProps={{
                      autoFocus: true,
                      name: "mobile_number",
                    }}
                    // disabled={disableGetCode}
                  />
              <span style={{ color: "red" }}>{mobile_numberErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="secondary" onClick={handleClose}>
            Close
          </Btn>
          <Btn variant="primary" onClick={() => profileHandler()}>
            Save
          </Btn>
        </Modal.Footer>
      </Modal>
    </>
  );
};
