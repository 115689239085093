import React, { useState, useEffect } from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { getPackageData } from "../../services/user";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";

import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
} from "ethers";
import {
  binanceChainId,
  bscRpcUrl,
  buyabi,
  buyaddress,
  explorerUrlBsc,
  tokenabi,
  tokenaddress,
} from "../../constant";
import { metaRequestInsert } from "../../services/user";
import { buyUrl } from "../../constant/BaseUrl";
import { Button } from "./partials/Button";

export const Package = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [record, setRecord] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [btndisabled, setDisabled] = useState(false);
  const [calculateRoi, setCalculateRoi] = useState({})
  const navigate = useNavigate();

  const projectId = "1d8e553b04494fc86b01065d081d9e93";

  const getPackage = async () => {
    const result = await getPackageData();

    if (result.status) {
      setRecord(result.data);
    }
  };



  useEffect(() => {
    getPackage();
  }, []);
  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
  ];

  const metadata = {
    name: "assetplus",
    description: "assetplus",
    url: "https://assetplus.com/",
    icons: ["https://assetplus.com/html/images/Logo.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const [errors, setErrors] = useState({});

  const handleInputChange = (e, index,id) => {
    const { value } = e.target;
    setInputValues((prev) => ({ ...prev, [index]: value }));
    setErrors((prev) => ({ ...prev, [index]: "" }));
    handleroi(id,index,value)
  };

 const handleroi=(id,index,amount)=>{
  const filter=record.filter((list)=>{return list.id==id})
  const daily_roi=(amount*filter[0]?.daily_roi)/100
  const monthly_roi=(amount*filter[0]?.monthly_roi)/100
  const total_roi=(amount*filter[0]?.total_roi)/100
  setCalculateRoi((prev)=>({...prev,[index]:{daily_roi,monthly_roi,total_roi}}))
 }

  // const handleDepositClick = async (item, index) => {
  //   const amount = parseFloat(inputValues[index]);

  //   if (isNaN(amount)) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       [index]: "Please enter a valid amount",
  //     }));
  //     return;
  //   }

  //   if (amount < item.min_amount) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       [index]: `Insufficient funds. Minimum amount is $${item.min_amount}`,
  //     }));
  //   } else if (amount > item.max_amount) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       [index]: `Amount exceeds maximum limit of $${item.max_amount}`,
  //     }));
  //   } else {
  //     setErrors((prev) => ({ ...prev, [index]: "" }));

  //     if (isConnected) {
  //       let inputamount = amount * 10 ** 18;
  //       inputamount = inputamount.toString();

  //       let balance;

  //       if (chainId != binanceChainId) {
  //         toastr.dismiss();
  //         toastr.error("Please change network to binanace smart chain");
  //         return;
  //       }

  //       const signer = await getSignerOrProvider(true);
  //       const provider = await getSignerOrProvider();

  //       const contract = new Contract(tokenaddress, tokenabi, provider);

  //       const checkA = await contract.allowance(address, buyaddress);
  //       if (Number(checkA) / 10 ** 18 < inputamount) {
  //         const contract = new Contract(tokenaddress, tokenabi, provider);

  //         balance = await contract.approve(
  //           buyaddress,
  //           "10000000000000000000000000000000000000000000000000000"
  //         );
  //         await balance.wait();
  //         toastr.success(
  //           "Approved successfully. Please don't refresh the page.fully. Please don't refresh the page."
  //         );
  //       }
  //       const contracts = new Contract(buyaddress, buyabi, provider);
  //       balance = await contracts.deposit(inputamount);
  //     } else {
  //       toastr.dismiss();
  //       toastr.error("Please connect with  wallet");
  //     }
  //   }
  // };

  const handleDepositClick = async (item, index) => {
    try {
      const amount = parseFloat(inputValues[index]);

      if (isNaN(amount)) {
        setErrors((prev) => ({
          ...prev,
          [index]: "Please enter a valid amount",
        }));
        return;
      }

      if (amount < item.min_amount) {
        setErrors((prev) => ({
          ...prev,
          [index]: `Minimum amount is $${item.min_amount}`,
        }));
        return;
      } else if (amount > item.max_amount) {
        setErrors((prev) => ({
          ...prev,
          [index]: `Amount exceeds maximum limit of $${item.max_amount}`,
        }));
        return;
      } else {
        setErrors((prev) => ({ ...prev, [index]: "" }));

        if (isConnected) {
          let balance;
          let inputamount = parseEther(amount.toString());

          inputamount = inputamount.toString();

          if (chainId !== binanceChainId) {
            toastr.error("Please change network to Binance Smart Chain");
            return;
          }

          const signer = await getSignerOrProvider(true);
          const provider = await getSignerOrProvider();

          const contract = new Contract(tokenaddress, tokenabi, provider);

          const checkA = await contract.allowance(address, buyaddress);

          if (Number(checkA) / 10 ** 18 < amount) {
            balance = await contract.approve(
              buyaddress,
              "10000000000000000000000000000000000000000000000000000"
            );
            await balance.wait();
            toastr.success(
              "Approved successfully. Please don't refresh the page."
            );
            window.location.reload()
          } else {
            setDisabled(true);
            const contracts = new Contract(buyaddress, buyabi, provider);
            balance = await contracts.deposit(inputamount);
            const transactionHash = balance.hash;
            const token = localStorage.getItem("jwtToken");
            const data = {
              transactionHash,
              wallet_address: address,
              inputAmt: amount,
              packageId: item.id,
            };

            const resp = await metaRequestInsert(data, token);
            if (resp.status) {
              toastr.success(
                "Your transaction proceed successfully please wait for a moment "
              );
            }

            await balance.wait();
            setDisabled(false);
          }
        } else {
          setDisabled(false);
          toastr.error("Please connect with a wallet");
        }
      }
    } catch (error) {
      setDisabled(false);

      if (
        error.message.includes(
          `execution reverted: "ERC20: insufficient allowance"`
        )
      ) {
        toastr.error("Insufficient allowance");
      }
      if (
        error.message.includes(
          `execution reverted: "Insufficient user balance"`
        )
      ) {
        toastr.error("Insufficient balance");
      }
    }
  };

  return (
    <>
      <div className="dash_bar_man">
        <Sidebar />
        <div className="d-md-flex mb-2 mb-md-4 align-items-center">
       
          <Button />  <h2 className="in_hadding order-md-1">Package</h2>
        </div>
        <div className="row row5">
          {record &&
            record.map((item, index) => {
              return (
                <div className="col-md-4 mb-4 mb-md-55" key={index}>
                  <div className="dash_box h100">
                    <h5 className="mb-3">{item?.package_name} (${item.min_amount} - ${item.max_amount})</h5>
                    <input
                      type="text"
                      className="form-control small_cntrol"
                      placeholder="Amount"
                      value={inputValues[index] || ""}
                      onChange={(e) => handleInputChange(e, index,item.id)}
                    />
                    {errors[index] && (
                      <p className="text-danger mb-0">{errors[index]}</p>
                    )}
                    <button
                      className="btn btn_man btn_man_small mt-3"
                      onClick={() => handleDepositClick(item, index)}
                      disabled={btndisabled}
                    >
                      <span>Deposit</span>
                    </button>
                    {calculateRoi[index] && (
                      <>
                      <p><b>Daily Roi :</b> {calculateRoi[index].daily_roi}</p>
                      <p><b>Monthly Roi :</b>{calculateRoi[index].monthly_roi}</p>
                      <p><b>Total Roi :</b>{calculateRoi[index].total_roi}</p>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
