import React from 'react'

export const Footer = () => {
  return (
    <>
      <section className="contact  footer text-center" id="contact" data-scroll-index={6}>
  <div className="container">
    <img src="/assets/img/logo.png" alt="header-Logo" className="logo" data-aos="fade-up" />
    <div className="nav_link mt-4 mb-4" data-aos="fade-up">
      <a href="#?">Privacy Policy</a>
      <a href="#?">Terms of Service </a>
      <a href="#?">Documentation</a>
    </div>
    <ul className="social_icon mb-34 mb-4"> 
      <li><a href="#"><i className="bi bi-facebook" /></a></li>
      <li><a href="#"><i className="bi bi-twitter" /></a></li>
      <li><a href="#"><i className="bi bi-linkedin" /></a></li>
      <li><a href="#"><i className="bi bi-telegram" /></a></li>
    </ul> 
    <div className=" text-left  " data-aos="fade-up">
      <b>Disclaimer: </b>
      <p>The success and earnings of participants in Asset Plus
        are entirely dependent on individual factors, including:</p>
      <p> Personal skills and expertise, 
        Quality of work and effort, 
        Level of commitment and dedication, 
        Leadership and management abilities, 
        Sales and marketing capabilities, 
        Prevailing market conditions</p>
      <p>Results may vary significantly. Asset Plus does not
        guarantee specific earnings or outcomes.</p>
        <p>
        Investors are solely responsible for ensuring compliance with the laws and regulations of their respective countries, including but not limited to laws governing cryptocurrency investments, taxation, and anti-money laundering (AML). Asset Plus assumes no responsibility for verifying or ensuring such compliance. By proceeding to invest, you acknowledge and accept that it is your obligation to ascertain the legality of cryptocurrency mining investments or any crypto related transactions and adhere to all applicable regulatory and tax requirements in your jurisdiction.
        </p>
    </div>
  </div>
  <div className="copyright">
    © 2024 Assetplus - All Rights Reserved
  </div>
</section>

    </>
  )
}
