import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button as Btn } from "react-bootstrap";
import { getPackageData, restakeFun } from "../../../services/user";
import toastr from "toastr";

function RestakeModal(props) {
  const [record, setRecord] = useState([]);
  const [amount, setAmount] = useState(100);
  const [amountErr, setAmountErr] = useState("");
  const [disable, setDisable] = useState(false);
  const [selectPackage, setSelectPackage] = useState({
    id: 1,
    package_name: "Basic",
    min_amount: "100",
    max_amount: "999",
    createdAt: "2023-05-04T04:47:22.000Z",
  });

  useEffect(() => {
    getPackage();
  }, []);

  const handleClose = () => {
    props.setShowRestake(false);
  };

  const getPackage = async () => {
    const result = await getPackageData();
    if (result.status) {
      setRecord(result.data);
    }
  };

  const handlePackege = (value) => {
    setSelectPackage(value);
    setAmount(value?.min_amount);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAmount(value);
    if (
      value < selectPackage?.min_amount &&
      value > selectPackage?.max_amount
    ) {
      setAmountErr("Please enter valid amount");
    } else {
      setAmountErr("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      amount < selectPackage?.min_amount &&
      amount > selectPackage?.max_amount
    ) {
      setAmountErr("Please enter valid amount");
      return;
    }
    setDisable(true);
    const data = { amount };
    const result = await restakeFun(data);
    if (result.status) {
      toastr.success(result.message);
      setDisable(false);
      handleClose();
      props?.AvailableBalanceHandler()
    } else {
      toastr.error(result.message);
      setDisable(false);
    }
  };

  return (
    <>
      <Modal show={props.showRestake} onHide={handleClose}>
        <Modal.Header closeButton>
          <h4>Restake</h4>
        </Modal.Header>

        <Modal.Body>
          <p>
            <b>Available Balance : </b> {props?.availableBalance}
          </p>
          <div>
            {record?.map((item) => {
              return (
                <button
                  className={`pack-btn ${
                    selectPackage.id == item.id ? "active" : ""
                  }`}
                  onClick={() => {
                    handlePackege(item);
                  }}
                >
                  {item?.package_name} (${item.min_amount} - ${item.max_amount})
                </button>
              );
            })}
            <div className="mt-2">
              <label className="form-label">
                {selectPackage?.package_name} (${selectPackage.min_amount} - $
                {selectPackage.max_amount})*
              </label>
              <input
                className="form-control"
                placeholder="Please Enter amount"
                value={amount}
                onChange={handleChange}
              />
              <span className="text-danger">{amountErr}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="secondary" onClick={handleClose}>
            Close
          </Btn>
          {props?.availableBalance >= selectPackage?.min_amount && (
            <Btn variant="primary" onClick={handleSubmit} disabled={disable}>
              Submit
            </Btn>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RestakeModal;
