export const signupApi = "/signup-user";
export const loginApi = "/login-user";

export const referralCodeApi = "/get-user-referral";

export const getPackageApi = "/get-package";
export const metaRequestInsertApi = "/metarequest-insert";
export const getPackageReportApi = "/get-package-report";
export const getDirectIncomeApi = "/get-direct-income";
export const getTeamBusinessApi = "/get-team-business";
export const getRoiApi = "/get-roi-income";
export const profileAddUpdateApi = "/profile-add-update";
export const profileGetApi = "/profile-get";
export const getTreeTeamApi = "/get-tree-team";
export const getMatchingApi = "/matching-report";
export const withdrawalApi = "/withdrawal";

export const AvailableBalanceApi = "/total-available-balance";
export const getWithdrawalReportApi = "/get-withdrawal-report";
export const getFilterDataApi = "/get-filter-data";

// ===========datewise=================//
export const getRoiDateWiseApi = "/get-roi-income-date-wise";

export const getReferralCodeApi = "/check-referral-code";

export const restakeApi="/restake-amount"

















//===================== team ======================
export const getTeamApi = "/signup-user";

//================= Video ===================
export const getVideoApi = "/get-video";

//================= Faq ===================
export const getFaqApi = "/get-faq";

//================= WhitePaper ===================
export const getWhitePaperApi = "/get-whitepaper";

//================= Partnership ===================
export const getPartnershipApi = "/get-partnership";

//================= SideBar ===================
export const getSideBarApi = "/get-sidebar";

//================== announcement =================
export const getAnnouncementApi = "/get-accouncement";

//================== Token sale Time ==================
export const getTokenSaletimeApi = "/get-tokensaletime";

//================= aboutus ===================
export const getAboutApi = "/get-about-us";

//================= contractAddress ===================
export const getContractApi = "/get-contract-address";

//================= walletAddress ===================
export const getWalletApi = "/get-wallet-address";

//================= ai ===================
export const getAiApi = "/get-ai";

//================== deso =================
export const getDesoApi = "/get-deso";

//================== gaming==================

export const getGamingApi = "/get-gaming";

//================== vision==================
export const getVisionApi = "/get-vision";
//================== vision==================
export const getMissionApi = "/get-mission";
//================== roadmap==================
export const getRoadmapApi = "/get-roadmap";
//================== socialmedia==================
export const getSocialmediaApi = "/get-social-media";

//================== document==================
export const getDocumentApi = "/get-document";
