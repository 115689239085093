import {
  signupApi,
  loginApi,
  referralCodeApi,
  getPackageApi,
  metaRequestInsertApi,
  getPackageReportApi,
  getDirectIncomeApi,
  getTeamBusinessApi,
  getRoiApi,
  profileAddUpdateApi,
  profileGetApi,
  withdrawalApi,
  AvailableBalanceApi,
  getWithdrawalReportApi,
  getFilterDataApi,
  getRoiDateWiseApi,
  getReferralCodeApi,
  restakeApi,
} from "../constant/Api";
import { baseUrl, baseUrlAdmin } from "../constant/BaseUrl";
import * as opsService from "./Ops";

const referralCode = async (data) => {
  const token = localStorage.getItem("jwtToken");
  let result = await opsService.postdata(
    baseUrl + referralCodeApi,
    data,
    token
  );
  return result;
};
const registerUser = async (data) => {
  let result = await opsService.postdata(baseUrl + signupApi, data);
  return result;
};
const loginUser = async (data) => {
  let result = await opsService.postdata(baseUrl + loginApi, data);
  return result;
};
const metaRequestInsert = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + metaRequestInsertApi,
    data,
    token
  );
  return result;
};

const getPackageData = async (data) => {
  let result = await opsService.getData(baseUrl + getPackageApi, data);
  return result;
};
const getPackageReport = async (data) => {
  let result = await opsService.postdata(baseUrl + getPackageReportApi, data);
  return result;
};
const getDirectIncome = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getDirectIncomeApi,
    data,
    token
  );
  return result;
};

const getTeamBusiness = async (data, token) => {
  let result = await opsService.getData(
    baseUrl + getTeamBusinessApi,
    data,
    token
  );
  return result;
};
const getRoi = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getRoiApi,
    data,

    token
  );
  return result;
};

const profileAddUpdate = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + profileAddUpdateApi,
    data,
    token
  );
  return result;
};
const profileGet = async (data, token) => {
  let result = await opsService.postdata(baseUrl + profileGetApi, data, token);
  return result;
};

const withdrawalAdd = async (data, token) => {
  let result = await opsService.postdata(
    baseUrlAdmin + withdrawalApi,
    data,
    token
  );
  return result;
};
const getWithdrawalReport = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getWithdrawalReportApi,
    data,
    token
  );
  return result;
};

const AvailableBalance = async (data, token) => {
  let result = await opsService.getData(
    baseUrl + AvailableBalanceApi,
    data,
    token
  );
  return result;
};
const getFilterData = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getFilterDataApi,
    data,
    token
  );
  return result;
};

const getRoiDateWise = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getRoiDateWiseApi,
    data,
    token
  );
  return result;
};

const checkReferralCode = async (data, token) => {
  let result = await opsService.postdata(
    baseUrl + getReferralCodeApi,
    data,

    token
  );
  return result;
};

const restakeFun = async (data) => {
  const token = localStorage.getItem("jwtToken");
  let result = await opsService.postdata(
    baseUrl + restakeApi,
    data,
    token
  );
  return result;
};


export {
  // checkReferralCode,
  referralCode,
  registerUser,
  loginUser,
  getPackageData,
  metaRequestInsert,
  getPackageReport,
  getDirectIncome,
  getTeamBusiness,
  getRoi,
  profileAddUpdate,
  profileGet,
  withdrawalAdd,
  AvailableBalance,
  getWithdrawalReport,
  getFilterData,
  getRoiDateWise,
  checkReferralCode,
  restakeFun
};
